'use client'

import { Box } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

import ProductLabelPromotion from 'components/ProductLabel/ProductLabelPromotion'
import ProductLabelImage from 'components/ProductLabel/ProductLabelImage'
import { parseProductLabels } from 'app/(product)/utils/helpers'

import { type ProductLabelType } from './types'

type ProductCardLabelPropsType = {
  productLabels: ProductLabelType[]
  productTitle: string
}

const ProductCardLabel = ({ productLabels, productTitle }: ProductCardLabelPropsType) => {
  if (!productLabels?.length) return null

  const { labelImage, labelPromotion } = parseProductLabels(productLabels)

  return (
    <>
      {labelImage && !!labelImage?.label_icon_url && (
        <Box position="absolute" top="4px" left="4px">
          <ProductLabelImage
            imageUrl={labelImage?.label_icon_url}
            alt={productTitle}
            width={20}
            height={20}
          />
        </Box>
      )}
      {labelPromotion && !!labelPromotion?.label_name && (
        <Box position="absolute" bottom="4px" width="calc(100% - 16px)" left="8px">
          <ProductLabelPromotion
            bgColor={labelPromotion.label_color_hexacode}
            textColor={labelPromotion.label_color_hexafont}
            text={labelPromotion.label_name}
          />
        </Box>
      )}
    </>
  )
}

export default ProductCardLabel
