'use client'

import 'keen-slider/keen-slider.min.css'
import { useEffect, useState, useRef, useCallback } from 'react'
import { Box, BoxPropsType } from '@astronautsid/wpe-astro-ui/components/atoms/Box'

import { useKeenSlider } from 'keen-slider/react.es'
import { type GetBannerContentType } from 'utils/apiList/lite/banner'
import { ASSET_PREFIX } from 'config/constants'
import useIntersection from 'hooks/useIntersection'
import {
  trackEachBannerImage,
  trackViewComponent,
  trackAppMonitoring,
} from 'utils/tracking/mixpanel/mixpanelEventActions'
import {
  BANNER_SWITCH_DURATION,
  NEW_BANNER_ASPECT_RATIO,
  NEW_BANNER_HEIGHT,
} from 'app/(home)/_config/banner'
import { useTheme } from '@astronautsid/wpe-astro-ui'
import { getComposedBannerURL } from 'utils/url/bannerUrl'

import NewBanner from '../Banner'

type BannerSectionPropsType = {
  banners: GetBannerContentType[]
  handleChangeTextColor: (color: string) => void
  boxProps?: BoxPropsType
  tickerShown?: boolean
}

const BannerSliderSection = ({
  boxProps,
  banners,
  handleChangeTextColor,
  tickerShown = false,
}: BannerSectionPropsType) => {
  const [isVisible, setIsVisible] = useState(false)
  const isVisibleRef = useRef(isVisible)
  const viewedBannerListIndexRef = useRef<number[]>([])
  const activeBannerIndexRef = useRef(0)
  const [currentSlide, setCurrentSlide] = useState<number>(0)
  const theme = useTheme()

  const containerRef = useIntersection({
    callback: () => {
      setIsVisible(true)
      isVisibleRef.current = true
    },
    options: { threshold: [0.95] },
    onLeaveCallback: () => {
      setIsVisible(false)
      isVisibleRef.current = false
    },
  })

  const handleTrackBanner = useCallback(
    (
      eventAction: 'click banner' | 'view banner',
      item: BannerSectionPropsType['banners'][0],
      index: number,
    ) => trackEachBannerImage({ eventAction, item, index }),
    [],
  )

  const handleTrackViewEachBanner = (currentIndex: number) => {
    const item = banners[currentIndex]
    handleTrackBanner('view banner', item, currentIndex)
  }

  const [isSliderCreated, setSliderCreated] = useState(!!banners.length)

  const [sliderRef, instanceSliderRef] = useKeenSlider(
    {
      renderMode: 'performance',
      ...(banners.length === 1
        ? {
            loop: false,
            slides: {
              perView: 1,
              origin: 'auto',
              spacing: 0,
            },
          }
        : {
            loop: true,
            slides: {
              perView: 'auto',
              origin: 'center',
              spacing: 0,
            },
          }),
    },
    [
      (slider) => {
        let timeout: ReturnType<typeof setTimeout>
        let mouseOver = false

        const clearNextTimeout = () => {
          clearTimeout(timeout)
        }

        const nextTimeout = () => {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            if (slider) {
              slider?.next()
            }
          }, BANNER_SWITCH_DURATION)
        }

        const handleMouseOver = () => {
          mouseOver = true
          clearNextTimeout()
        }

        const handleMouseOut = () => {
          mouseOver = false
          nextTimeout()
        }

        slider.on('created', () => {
          const currentSlideIndex = slider?.track?.details?.rel
          handleChangeTextColor(banners[currentSlideIndex].text_color)

          setSliderCreated(true)
          slider.update()

          slider.container.addEventListener('mouseover', handleMouseOver)
          slider.container.addEventListener('mouseout', handleMouseOut)
          nextTimeout()
        })
        /* Cleanup */
        slider.on('destroyed', () => {
          slider.container.removeEventListener('mouseover', handleMouseOver)
          slider.container.removeEventListener('mouseout', handleMouseOut)
          clearNextTimeout()

          activeBannerIndexRef.current = 0
          viewedBannerListIndexRef.current = []
        })

        slider.on('dragStarted', clearNextTimeout)
        slider.on('animationEnded', nextTimeout)
        slider.on('updated', nextTimeout)
        slider.on('slideChanged', () => {
          const currentSlideIndex = slider?.track?.details?.rel
          /*
            save current active banner index to track the first active banner again
            when slider component is back into viewport.
          */
          activeBannerIndexRef.current = currentSlideIndex
          setCurrentSlide(currentSlideIndex)
          handleChangeTextColor(banners[currentSlideIndex].text_color)
          const isViewed = viewedBannerListIndexRef.current?.find(
            (index) => index === currentSlideIndex,
          )

          if (isViewed === undefined && isVisibleRef.current) {
            const currentValue = viewedBannerListIndexRef.current
            viewedBannerListIndexRef.current = [...currentValue, currentSlideIndex]
            handleTrackViewEachBanner(currentSlideIndex)
          }
        })
      },
    ],
  )

  const handleTrackClickBanner = (item: BannerSectionPropsType['banners'][0], index: number) => {
    handleTrackBanner('click banner', item, index)
  }

  const handleTrackAppMonitoring = (eventLabel: string, i: GetBannerContentType) => {
    trackAppMonitoring({
      eventAction: 'app monitoring - banner load',
      eventLabel,
      items: [i],
    })
  }

  useEffect(() => {
    if (banners.length) {
      trackViewComponent({ eventAction: 'view internal asset - carousel', eventLabel: '' })

      /*
        Track the index 0 banner when the page first loads or
        when the banner is the only one, since the slideChanged event is not active.
        Also track the current active banner when slider component return to viewport.
      */
      const activeBannerIndex = activeBannerIndexRef.current
      const isViewed = viewedBannerListIndexRef.current?.find(
        (index) => index === activeBannerIndex,
      )

      if (isVisible && isViewed === undefined) {
        handleTrackBanner('view banner', banners[activeBannerIndex], activeBannerIndex)
      }
    }
  }, [banners.length, isVisible, banners, handleTrackBanner])

  const renderInitialBannerPreview = () => {
    if (isSliderCreated || !banners.length) {
      return null
    }

    const bannerPreview = banners[0]

    return (
      <Box sx={{ zIndex: 1, margin: '0 auto' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <NewBanner
            src={
              bannerPreview.new_banner_image_url.image_url_small
                ? bannerPreview.new_banner_image_url.image_url_small
                : `${ASSET_PREFIX}/img/placeholder-product.svg`
            }
            alt={`preview-banner-${bannerPreview?.banner_name || ''}`}
          />
        </Box>
      </Box>
    )
  }

  return (
    <Box
      ref={containerRef}
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        maxHeight: !tickerShown ? NEW_BANNER_HEIGHT : `calc(${NEW_BANNER_HEIGHT} + 55px)`,
      }}
      {...boxProps}
    >
      {renderInitialBannerPreview()}
      {banners?.length > 1 && isSliderCreated && (
        <Box
          display="flex"
          gap="8px"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          sx={{ left: '50%', transform: 'translateX(-50%)', bottom: '12px', zIndex: 2 }}
        >
          {banners.map((_, idx) => {
            const key = idx

            return (
              <Box
                key={key}
                onClick={() => {
                  instanceSliderRef.current?.moveToIdx(idx)
                }}
                width="6px"
                height="6px"
                borderRadius="50%"
                sx={{
                  bgcolor:
                    currentSlide === idx
                      ? String(theme.palette.bgColor.primary)
                      : String(theme.palette.bgColor.lightGrey),
                }}
              />
            )
          })}
        </Box>
      )}
      <Box
        ref={sliderRef}
        className="keen-slider"
        width="100%"
        sx={{
          margin: '0 auto',
          maxHeight: !tickerShown ? NEW_BANNER_HEIGHT : `calc(${NEW_BANNER_HEIGHT} + 55px)`,
          opacity: isSliderCreated ? 1 : 0,
        }}
      >
        {banners.map((i, idx) => {
          const key = `${i.banner_id}-${idx + 1}`

          if (!i.new_banner_image_url.image_url_small) {
            return null
          }

          const targetURL = getComposedBannerURL(i)

          return (
            <NewBanner
              tickerShown={tickerShown}
              key={key}
              href={targetURL}
              boxProps={{
                className: 'keen-slider__slide',
                sx: { aspectRatio: NEW_BANNER_ASPECT_RATIO },
              }}
              src={
                i.new_banner_image_url.image_url_medium
                  ? i.new_banner_image_url.image_url_medium
                  : `${ASSET_PREFIX}/img/placeholder-product.svg`
              }
              alt={i?.banner_name || ''}
              onClick={() => handleTrackClickBanner(i, idx)}
              onLoad={() => handleTrackAppMonitoring('success', i)}
              onError={() => handleTrackAppMonitoring('failed - Image 404 Not Found', i)}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default BannerSliderSection
