import { type GetBannerContentType } from 'utils/apiList/lite/banner'

export const BANNER_HEIGHT = 288

export const BANNER_WIDTH = 656

export const BANNER_ASPECT_RATIO = BANNER_WIDTH / BANNER_HEIGHT

export const MAX_WIDTH_BANNER_CONTAINER = {
  minWidth: 'calc(100% - 32px) !important',
  maxWidth: 'calc(100% - 32px) !important',
}

export const NEW_BANNER_HEIGHT = 320

export const NEW_BANNER_WIDTH = 360

export const NEW_BANNER_ASPECT_RATIO = NEW_BANNER_WIDTH / NEW_BANNER_HEIGHT

export const NEW_MAX_WIDTH_BANNER_CONTAINER = {
  minWidth: '100%',
  maxWidth: '100%',
}

export const BANNER_SWITCH_DURATION = 4 * 1000

type MapDeeplinkPathType = {
  [k: string]: {
    getTargetUrl: (restPath?: string, isCatalogue?: boolean) => string
  }
}

const MAP_DEEPLINK_PATH: MapDeeplinkPathType = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '/category/': {
    getTargetUrl: (restPath = '', isCatalogue) => {
      if (isCatalogue) {
        return `/cat/${restPath}`
      }

      return `/c/${restPath}`
    },
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '/catalog/': {
    getTargetUrl: (restPath = '') => `/cat/${restPath}`,
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '/promo/': {
    getTargetUrl: (restPath = '') => `/promo/${restPath}`,
  },
}

const SUPPORTED_DEEPLINK_PATH = Object.keys(MAP_DEEPLINK_PATH)

export const checkAstroDeepLinkUrl = (url: string) => {
  const re = /^(astronauts:\/\/[a-z]*)/

  return re.test(url)
}

export const getMetaAstroDeeplinkUrl = (bannerItem: GetBannerContentType) => {
  const { banner_url_link: url = '', category_id, is_catalogue: isCatalogue } = bannerItem

  // 1st case
  if (category_id) {
    const targetUrl = MAP_DEEPLINK_PATH['/category/'].getTargetUrl(String(category_id), isCatalogue)

    return {
      targetUrl,
    }
  }

  // 2nd case
  const isAstroDeeplinkUrl = checkAstroDeepLinkUrl(url)

  if (isAstroDeeplinkUrl) {
    const deeplinkPaths = url.split('/')
    const pathL1 = deeplinkPaths[1] || ''

    const restPath = deeplinkPaths.slice(2).join('/')

    const isPathSupported = SUPPORTED_DEEPLINK_PATH.includes(pathL1)

    const targetUrl = isPathSupported
      ? MAP_DEEPLINK_PATH[pathL1].getTargetUrl(restPath, isCatalogue)
      : ''

    return { targetUrl }
  }

  return {
    targetUrl: '',
  }
}
