import { getMetaAstroDeeplinkUrl } from 'app/(home)/_config/banner'
import { type GetBannerContentType } from 'utils/apiList/lite/banner'
import { type CategoryContentType } from 'utils/apiList/lite/liteApiTypes'

type MapDeeplinkPathType = {
  [k: string]: {
    getTargetUrl: (restPath?: string, isCatalogue?: boolean) => string
  }
}

const MAP_DEEPLINK_PATH: MapDeeplinkPathType = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '/category/': {
    getTargetUrl: (restPath = '', isCatalogue) => {
      if (isCatalogue) {
        return `/cat/${restPath}`
      }

      return `/c/${restPath}`
    },
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '/catalog/': {
    getTargetUrl: (restPath = '') => `/cat/${restPath}`,
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '/promo/': {
    getTargetUrl: (restPath = '') => `/promo/${restPath}`,
  },
}

export const SUPPORTED_DEEPLINK_PATH = Object.keys(MAP_DEEPLINK_PATH)

export const checkAstroDeepLinkUrl = (url: string) => {
  const re = /^(astronauts:\/\/[a-z]*)/

  return re.test(url)
}

export const getPathFromDeepLink = (deepLink: string) => {
  const paths = deepLink.replace(/^.*?:\/\//, '')

  return `/${paths}`
}

export const getComposedBannerURL = (item: GetBannerContentType) => {
  const url = item.banner_url_link || ''

  if (url && url.includes('https')) {
    return url
  }

  const { targetUrl } = getMetaAstroDeeplinkUrl(item)

  return targetUrl
}

export const getComposedDeeplinkURL = (url: string) => {
  if (url.includes('https')) {
    return url
  }

  const isAstroDeeplinkUrl = checkAstroDeepLinkUrl(url)

  if (!isAstroDeeplinkUrl) return ''

  const deeplinkPaths = getPathFromDeepLink(url)

  let isPathSupported = false
  let selectedDeeplinkPath = ''

  SUPPORTED_DEEPLINK_PATH.forEach((i) => {
    if (deeplinkPaths.includes(i)) {
      isPathSupported = true
      selectedDeeplinkPath = i
    }
  })

  const restPath = isPathSupported ? deeplinkPaths.replace(selectedDeeplinkPath, '') : ''

  return isPathSupported ? MAP_DEEPLINK_PATH[selectedDeeplinkPath].getTargetUrl(restPath) : ''
}

export const getComposedCategoryURL = (
  item: Pick<CategoryContentType, 'destination_url' | 'category_slug'>,
  isCatalogue?: boolean,
) => {
  const { destination_url: url = '', category_slug } = item

  if (!url) {
    return isCatalogue ? `/cat/${category_slug}` : `/c/${category_slug}`
  }

  return getComposedDeeplinkURL(url)
}
