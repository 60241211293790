type InventoryDiscountTierLabelType = {
  quantity: number
  discountPercentage: string
  finalPrice: number
  label: string
}

const calculateBuyMore = (
  inventoryDiscountTierLabel: InventoryDiscountTierLabelType[],
  productQty: number,
  productPrice: string,
) => {
  const sortedDiscountTiers = [...inventoryDiscountTierLabel].sort(
    (a, b) => b.quantity - a.quantity,
  )
  const fullfilledDiscountTier = sortedDiscountTiers.find(
    (discountTier) => productQty >= discountTier.quantity,
  )

  const defaultLabel = inventoryDiscountTierLabel?.[0]?.label || ``

  return {
    discountPrice: fullfilledDiscountTier?.finalPrice?.toString() || productPrice,
    discountLabel: fullfilledDiscountTier?.label || defaultLabel,
    discountPercentageStr: fullfilledDiscountTier?.discountPercentage || ``,
  }
}

export default calculateBuyMore
